<template>
    <div>
        <b-card title="Add warehouse">

            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="addObject.name"/>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Lat</label>
                        <b-form-input :value="addObject.location[1]" v-on:input="updateMarkerLocationLat"/>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label>Lng</label>
                        <b-form-input :value="addObject.location[0]" v-on:input="updateMarkerLocationLng"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <div id="map" style="width: 100%; height: 500px"></div>

            <hr/>
            <b-button variant="primary" @click="addWarehouse">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </b-card>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            BCard,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BButton
        },
        data() {
            return {

                map: null,
                marker: null,

                addObject: {
                    name: '',
                    location: [
                        this.$store.state.app.default_map_view.center[0],
                        this.$store.state.app.default_map_view.center[1]
                    ]
                }
            }
        },
        methods: {
            addWarehouse() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/warehouse', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Warehouse added')
                    thisIns.$router.go(-1)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(this.$store.state.app.default_map_view.center).addTo(this.map)

                this.marker.on('dragend', this.onMarkerDragEnd)
            },
            onMarkerDragEnd() {
                this.$set(this.addObject.location, 0, this.marker.getLngLat().lng)
                this.$set(this.addObject.location, 1, this.marker.getLngLat().lat)
            },
            updateMarkerLocationLat(val) {
                this.$set(this.addObject.location, 1, val)
                this.marker.setLngLat(this.addObject.location)
            },
            updateMarkerLocationLng(val) {
                this.$set(this.addObject.location, 0, val)
                this.marker.setLngLat(this.addObject.location)
            }
        },
        mounted() {
            this.attachMap()
            this.loadData()
        }
    }
</script>